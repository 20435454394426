import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { graphql } from 'gatsby';
import SEO from '@components/SEO';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Header from '@components/Header';

const PrivacyPolicy = ({
    data: {
        page: {
            frontmatter: { title, text },
        },
    },
}) => {
    console.log({title});
    return (
        <Layout>
            <SEO title={`Privacy Policy`} />
            <div className={styles.privacyWrapper}>
                <div className={styles.privacyContent}>
                    <h1>{title}</h1>
                    {!!text && <div dangerouslySetInnerHTML={{ __html: text }} />}
                </div>
            </div>

            <Divider />
            <Divider />
        </Layout>
    );
};

export default PrivacyPolicy;

export const query = graphql`
    query privacyPolicyPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: privacy_policy_title
                text: privacy_policy_text
            }
        }
    }
`;
